import React from 'react'
import { Link } from 'gatsby'
import cookies from 'react-cookies'
const HiringWithMedRepsHero = () => {
  let user = cookies.load('user')
  return (
    <>
      <div
        className="w-full bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(/images/hiring-with-medReps-Hero.png)` }}
      >
        <div className="container mx-auto pb-48 md:pb-[24rem] px-16 md:px-0">
          <div className="w-full md:w-[45%] mx-auto pt-32">
            <h1 className="text-4xl text-white text-center m-0">Post Jobs</h1>
            <p className="text-xl text-white text-center m-0 py-3">
              Post all your medical sales jobs
            </p>
            <p className="text-base text-white text-center">
              Unlimited Jobs is an unlimited job posting plan that simplifies
              hiring while reducing your costs. Cancel anytime.
            </p>
          </div>
          <div className="w-full md:w-[80%] lg:w-[60%] mx-auto flex flex-col md:flex-row justify-between pt-10 gap-4">
            <div className="bg-[#FFF8F8] w-full md:w-[30%] px-12 py-8 rounded-lg min-h-[400px] flex flex-col justify-between mb-12 md:mb-0">
              <div>
                <h1 className="text-xl font-bold m-0 pb-1">Monthly</h1>
                <h1 className="text-4xl font-bold m-0 pb-2">$525</h1>
                <span className="text-[#B4B4B4] text-base">a month</span>
                <p className="text-[#B4B4B4] pt-7 text-base">
                  Renews monthly for uninterrupted service
                </p>
              </div>
              <div>
                {!user && (
                  <Link to="/employer/signup/">
                    <button className="bg-voyage w-full py-2 text-white font-sans font-bold rounded-md">
                      Get Monthly Plan
                    </button>
                  </Link>
                )}
              </div>
            </div>
            <div className="bg-[#FFF8F8] w-full md:w-[30%] px-12 py-8 rounded-lg min-h-[400px] flex flex-col justify-between mb-12 md:mb-0">
              <div>
                <h1 className="text-xl font-bold m-0 pb-1">Quarterly</h1>
                <h1 className="text-4xl font-bold m-0 pb-2">$1,195</h1>
                <span className="text-[#B4B4B4] text-base">3 months</span>
                <div className="text-[#A51440] font-semibold bg-[#FBEFF3] rounded-md w-fit px-3 py-1 mt-2">
                  Save 24%
                </div>
                <h1 className="text-xl font-bold pt-1 m-0">$398.33</h1>
                <span className="text-[#B4B4B4] text-base">/ month</span>
                <p className="text-[#B4B4B4] pt-6 text-base">
                  Renews quarterly for uninterrupted service
                </p>
              </div>
              <div>
                {!user && (
                  <Link to="/employer/signup/">
                    <button className="bg-voyage w-full py-2 text-white font-sans font-bold rounded-md">
                      Get Quarterly Plan
                    </button>
                  </Link>
                )}
              </div>
            </div>
            <div className="bg-[#FFF8F8] w-full md:w-[30%] px-12 py-8 rounded-lg min-h-[400px] flex flex-col justify-between mb-12 md:mb-0">
              <div>
                <h1 className="text-xl font-bold m-0 pb-1">Annual</h1>
                <h1 className="text-4xl font-bold m-0 pb-2">$2,625</h1>
                <span className="text-[#B4B4B4] text-base">a year</span>
                <div className="text-[#A51440] font-semibold bg-[#FBEFF3] rounded-md w-fit px-3 py-1 mt-2">
                  Save 60%
                </div>
                <h1 className="text-xl font-bold pt-1 m-0">$218.75</h1>
                <span className="text-[#B4B4B4] text-base">/ month</span>
                <p className="text-[#B4B4B4] pt-6 text-base">
                  Renews annually for uninterrupted service
                </p>
              </div>
              <div>
                {!user && (
                  <Link to="/employer/signup/">
                    <button className="bg-voyage w-full py-2 text-white font-sans font-bold rounded-md">
                      Get Annual Plan
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto bg-[#FEF8EA] mt-16 w-[90%] md:w-[70%] mx-auto py-16 px-4 md:px-20 -mt-[9rem] md:-mt-[17rem]">
        <div className="pb-8">
          <h1 className="text-[#6D1E36] text-4xl m-0 pb-2">Plan Add-Ons</h1>
          <p className="text-xl">
            Smart add-ons to make your recruitment strategy even more effective
          </p>
        </div>
        <div className="pb-10">
          <h1 className="text-3xl m-0 pb-2">Resume Search</h1>
          <p className="text-xl">
            Search thousands of active medical sales professional resumes and
            receive Resume Alert emails matching your pre-defined search
            criteria
          </p>
          <Link to="/employer/signup/">
            <h1 className="text-[#6D1E36] text-3xl m-0 pb-2">
              Add to your plan for $195/month
            </h1>
          </Link>
        </div>
        <div className="pb-8">
          <h1 className=" text-3xl m-0 pb-2">Featured Job Upgrades</h1>
          <p className="text-xl">
            Upgrade your job to make your posting stand out in search results
            and catch the attention of job seekers. Featured Jobs also receive
            priority in Job Alert emails. The upgrade can be applied to any
            active job, and lasts for 30 days, or for the remaining time the job
            posting will be live.
          </p>
          <div
            className={`${
              user ? 'pointer-events-none' : 'pointer-events-auto'
            }`}
          >
            <Link to="/employer/signup/">
              <h1 className={`text-[#6D1E36] text-3xl m-0 pt-2 `}>
                Purchase Featured Job credits for $100 each
              </h1>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
export default HiringWithMedRepsHero
